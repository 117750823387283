import { Hero, Products } from "../components";

const Home = () => {

    return (<>
        {/* <Hero /> */}
        <div className="relative h-96">
            <h2 className="text-2xl font-bold m-4">Our Products (Coming Soon...)</h2>
            <Products  />
            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4"> */}
                {/* {products.map((product, index) => ( */}
                    {/* <Products key={index} {...product} onAddToCart={() => addToCart(product)} /> */}
                    
                {/* ))} */}
            {/* </div> */}
            <h2 className="text-2xl font-bold m-4"></h2>
        </div>
    </>)
};

export default Home;
