// import Carousel from "./carousel";

const images = [
  'FaceWash.jpeg',
  'BodyLotion.jpeg',
  'SkinCare.jpeg',
  'SpfSunscreen.jpeg',
];

const Product = ({ name, price, onAddToCart, src }) => {
  // <Carousel />
  return (
    <div className="flex flex-wrap">
      {images.map((image, index) => (
        <div key={index} className="w-full p-1"> {/* Use p-1 for small gaps */}
          <img
            src={image}
            alt={`Image ${index + 1}`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      ))}
    </div>
  );
};


export default Product