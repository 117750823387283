import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();

  return (
    <nav className="bg-primary p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="container mx-auto flex items-center gap-2">
          {/* <img src="logo.png" width="120px" height="60px" /> */}
          <div className="text-white font-bold text-xl">Secret Swans</div>
        </div>

        <div className="space-x-4">
          <a href="/" className="text-white hover:text-blue-200">Home</a>
          {/*<a href="#" className="text-white hover:text-blue-200">Products</a> */}
          <a href="#" className="text-white hover:text-blue-200" onClick={() => navigate('/about')}>About</a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;