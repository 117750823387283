const About = () => {
    return (
        <div className="secret-swans-content max-w-4xl mx-auto px-4 py-8 font-serif">
            <h1 className="text-4xl font-normal text-purple-400 mb-6">Secret Swans: Where Elegance Takes Flight</h1>

            <p className="text-gray-700 mb-4">At Secret Swans, we understand that the years between childhood and adolescence are a time of transformation, curiosity, and blossoming confidence. It's a phase where young girls begin to explore who they are and how they express themselves, while still holding onto the innocence of childhood. That's where Secret Swans, a luxurious pre-teen cosmetic brand by Irawell Lifestyle Pvt Ltd, comes in.</p>

            <p className="text-gray-700 mb-4">Designed specifically for girls aged 6 to 12, Secret Swans is the perfect stepping stone for those delicate early years, a time when beauty is discovered, not defined. This luxury brand creates a safe, nurturing environment for girls to experiment with their first cosmetics, helping them build confidence as they prepare for their teenage years. With carefully curated products, we are committed to providing young girls with an experience of elegance, fun, and self-care, wrapped in nature's kindness and purity.</p>

            <h2 className="text-2xl font-normal text-purple-400 mt-8 mb-4">The Grace of Safe and Natural Beauty</h2>

            <p className="text-gray-700 mb-4">For the pre-teen age range, safety and gentle care are paramount. The skin of young girls is tender, more sensitive than that of teenagers or adults, and it deserves extra protection. That's why Secret Swans is built on a foundation of purity, safety, and trust.</p>

            <p className="text-gray-700 mb-4">Our entire product range is toxic chemical-free, lead-free, and made from natural ingredients, ensuring that every drop, every swipe, and every dab of Secret Swans is as gentle as a mother's touch. We've carefully selected botanical extracts, essential oils, and other nature-based ingredients that hydrate, nourish, and protect young skin. Whether it's our shampoo infused with mild cleansers, our body lotion that locks in moisture, or our sunscreen that shields delicate skin from harmful UV rays, each product is formulated to meet the highest safety standards, giving parents peace of mind and girls the confidence they need to explore beauty freely.</p>

            <h2 className="text-2xl font-normal text-purple-400 mt-8 mb-4">A Ritual of Care and Confidence</h2>

            <p className="text-gray-700 mb-4">Our collection of premium cosmetics is designed to make self-care fun, creative, and easy for pre-teens. From a bubbly body wash that leaves skin soft and fresh, to a silky face wash that maintains a healthy glow, Secret Swans is a daily ritual of care. Our lip oils and lip glosses come in playful yet subtle shades, adding a hint of sparkle that makes every smile shine brighter, while our cheek tint offers a pop of color, perfect for that first taste of makeup.</p>

            <p className="text-gray-700 mb-4">What makes Secret Swans truly unique is that we aren't just providing cosmetics—we are helping young girls build a foundation for self-love and care. Our products are designed to help pre-teens discover their own beauty, encouraging them to embrace their natural features and feel comfortable in their own skin. Every use of our product is an opportunity to celebrate self-expression, all while staying true to a safe, natural, and clean beauty philosophy.</p>

            <h2 className="text-2xl font-normal text-purple-400 mt-8 mb-4">Gentle for Today, Empowering for Tomorrow</h2>

            <p className="text-gray-700 mb-4">As girls prepare to step into the world of teenage beauty brands, Secret Swans helps them take flight, giving them the tools they need to explore and experiment without compromising on safety or quality. This is not just beauty for the outside, but an empowering message for the inside—that being unique, natural, and true to oneself is the essence of elegance.</p>

            <p className="text-gray-700 mb-4">Our promise at Secret Swans is simple: to provide luxury beauty products that cater to the unique needs of pre-teen girls. By combining the finest natural ingredients with laboratory-tested formulas, we ensure that each product is not only safe but also enhances the natural grace of our young swans. As they grow, learn, and evolve, Secret Swans will be their trusted companion, helping them shine at every stage of their journey.</p>

            <h2 className="text-2xl font-normal text-purple-400 mt-8 mb-4">Welcome to Secret Swans</h2>

            <p className="text-gray-700 mb-4">At Secret Swans, we believe that every girl deserves to feel beautiful—inside and out. We are here to create a luxurious, nurturing experience that girls and their parents can trust, knowing that our products are designed specifically for this important stage in life. With nature at the heart of every product, Secret Swans brings a touch of elegance and a lot of love to each girl's personal journey.</p>

            <p className="text-gray-700 mb-4">Because every swan's beauty takes time to unfold, and we're here to make that transformation as graceful and magical as possible. Come embrace grace!</p>
        </div>
    );
};

export default About;
