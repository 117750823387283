import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigation } from './components';
import About from './pages/about';
import Home from './pages/home';

const App = () => {
  const [cartItems, setCartItems] = useState([]);
  // const [showCart, setShowCart] = useState(false);



  const addToCart = (product) => {
    setCartItems([...cartItems, product]);
  };

  // const total = cartItems.reduce((sum, item) => sum + item.price, 0);

  return (
    <BrowserRouter basename="/">
      <div className="min-h-screen bg-gray-100">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
